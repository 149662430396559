'use strict';

import { on, throttle } from './modules/_fn';

/* ---------------------------------------- [START] Document Ready */
(function (window, document) {
	/* ---------------------------------------- [START] Windows Setting */
	const html = document.documentElement;
	const body = document.body || document.querySelector('body');
	let ww = window.innerWidth;
	let wh = window.innerHeight;
	let ws = 0;
	function getScrollTop(target = window) {
		return (target.pageYOffset || html.scrollTop) - (html.clientTop || 0);
	}
	function getWinSet() {
		ww = window.innerWidth;
		wh = window.innerHeight;
		ws = getScrollTop();
	}
	on(window, 'load', getWinSet);
	on(window, 'resize', throttle(getWinSet, 50, 100));
	/* ---------------------------------------- [END] Windows Setting */

	/* ---------------------------------------- [START] 取得裝置判斷 */
	// 取得裝置判斷
	let isMobile = false;
	let isTablet = false;
	let isPhone = false;

	const deviceDetect = function () {
		// IsPhone
		isPhone = ww <= 640;

		// IsMobile
		// 防止測試時一直用開發者工具Resize出現Bug
		isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
		if (isMobile) html.classList.add('is-mobile');
		else html.classList.remove('is-mobile');

		// IsTablet
		if (navigator.userAgent.match(/Android/i)) {
			if (!navigator.userAgent.match(/Mobile/i)) {
				isTablet = true;
			}
		} else if (navigator.userAgent.match(/BlackBerry|iPad|Opera Mini|IEMobile/i)) {
			isTablet = true;
		}
	};

	deviceDetect();
	on(window, 'resize', throttle(deviceDetect, 50, 100));
	/* ---------------------------------------- [END] 取得裝置判斷 */

	/* ---------------------------------------- [START] 判斷browser */
	var ua = navigator.userAgent;
	var browser = {
		isChrome: /chrome/i.test(ua),
		isFirefox: /firefox/i.test(ua),
		isSafari: /safari/i.test(ua),
		isIE: /msie/i.test(ua) || /trident/i.test(ua),
		isEdge: /edge/i.test(ua),
	};

	// 修正數值browser
	if (browser.isChrome) browser.isSafari = false;
	if (browser.isEdge) {
		browser.isChrome = false;
		browser.isSafari = false;
	}

	var browserIs;
	for (var key in browser) {
		if (browser[key]) {
			browserIs = key.split('is')[1];
			// 增加Class
			document.documentElement.classList.add(browserIs.toLowerCase());
			document.documentElement.browser = browserIs;
			break;
		}
	}
	browser.is = browserIs;

	// ----------------------------
	// 判斷 裝置
	// iOS
	var isiOS = ua.toLowerCase().match(/(iphone|ipod|ipad)/);
	isiOS && html.classList.add('ios');
	/* ---------------------------------------- [END] 判斷browser */

	/* ----------------------------------- [START] Loader 移除 */
	var loaderRemove = function () {
		var loader = document.querySelector('#loader');
		window.loader = loader; // 加到 window 上

		pageLoaded();
	};
	on(window, 'load', loaderRemove);

	/* 頁面可呼叫的 function -------- */
	/* 開啟 Loading */
	window.pageLoading = function () {
		loader.classList.remove('loaded');
		document.body.appendChild(loader);
	}

	/* 關閉 Loading */
	window.pageLoaded = function () {
		loader.classList.add('loaded');
		setTimeout(function () {
			loader.remove();
		}, 2500);
	}
	/* ----------------------------------- [END] Loader 移除 */

	/* ---------------------------------------- [START] LazyLoad */
	/*
	 * 使用：https://github.com/verlok/vanilla-lazyload
	 * 尋找頁面上的 .lazy 為執行 Lazy Load 物件
	 */
	var lazyloadTimer = 0;
	function buildLazyLoad() {
		if (lazyloadTimer < 5 && window.LazyLoad === undefined) {
			return setTimeout(function () {
				lazyloadTimer++;
				buildLazyLoad();
			}, 500);
		}

		var lazyLoadInstance = new LazyLoad({
			// Your custom settings go here
		});
	}
	on(window, 'load', buildLazyLoad);
	/* ---------------------------------------- [END] LazyLoad */

	/* ---------------------------------------- [START] Fetch Svg Inline */
	function buildSvgFetchInline() {
		if (fetchSvgInline === undefined) {
			return false
		}

		const svgImgs = document.querySelectorAll('img.svg');
		[].forEach.call(svgImgs, (svg) => {
			fetchSvgInline(svg)
		});
	}
	on(window, 'load', buildSvgFetchInline);
	/* ---------------------------------------- [END] Fetch Svg Inline */

	/* ----------------------------------- [START] Bootstrap */
	on(window, 'load', function () {
		// Start
	});
	/* ----------------------------------- [END] Bootstrap */

	/* ---------------------------------------- [START] ScrollTriggerAni */
	// 有滑動到該區增加動態者增加class "js-ani" ， 滑動到該區增加class "is-animated"
	let aniSec = document.querySelectorAll('.js-ani');
	const scrollTriggerAniThrottle = throttle(scrollTriggerAni, 200, 500); // 節流作用

	function scrollTriggerAni() {
		for (var i = 0; i < aniSec.length; i++) {
			if (isElInViewport(aniSec[i])) {
				aniSec[i].classList.add('is-animated');
			}
		}

		cleanTrigger();

		/* If load all of the item, stop Listener */
		if (aniSec.length === 0) {
			window.addEventListener('scroll', scrollTriggerAniThrottle);
		}
	}

	function cleanTrigger() {
		aniSec = Array.prototype.filter.call(aniSec, function (ani) {
			return !ani.classList.contains('is-animated');
		});
	}

	function isElInViewport(el) {
		const rect = el.getBoundingClientRect();
		const isVisible = el.offsetHeight !== 0;

		return (
			isVisible &&
			rect.bottom >= 0 &&
			rect.right >= 0 &&
			rect.top + 100 <= (window.innerHeight || document.documentElement.clientHeight) &&
			rect.left <= (window.innerWidth || document.documentElement.clientWidth)
		);
	}

	on(window, 'load', scrollTriggerAni);
	on(window, 'scroll', scrollTriggerAniThrottle);
	/* ---------------------------------------- [END] ScrollTriggerAni */

	/* ---------------------------------------- [START] easeScroll */
	function buildEaseScroll() {
		$("html").easeScroll({
			frameRate: 20,
			animationTime: 2000,
			stepSize: 35,
			pulseAlgorithm: !0,
			pulseScale: 8,
			pulseNormalize: 1,
			accelerationDelta: 20,
			accelerationMax: 1,
			keyboardSupport: !0,
			arrowScroll: 50
		});
	}

	on(window, 'load', buildEaseScroll);
	/* ---------------------------------------- [END] easeScroll */

	/* ---------------------------------------- [START] 選單下滑更改樣式 */
	const doc = document.documentElement;
	const header = document.querySelector('#hd');
	const footer = document.querySelector('#ft');
	const headerFixedClass = 'is-scroll';

	function headerScrollHandler() {
		const windowScrollTop = (window.pageYOffset || doc.scrollTop) - (doc.clientTop || 0);
		if (windowScrollTop > header.offsetHeight) {
			header.classList.add(headerFixedClass);
		} else {
			header.classList.remove(headerFixedClass);
		}

		if (windowScrollTop + wh <= footer.offsetTop) {
			$('.js-fixd-service').addClass('is-show');
		} else {
			$('.js-fixd-service').removeClass('is-show');
		}
	}

	on(window, 'scroll', headerScrollHandler);
	on(window, 'load', headerScrollHandler);
	/* ---------------------------------------- [END] 選單下滑更改樣式 */

	/* ---------------------------------------- [START] header選單 */
	function checkIsPc() {
		if (ww > 1024) {
			// stopHtmlScrolling(false);

			$('#hd').removeClass('is-open');
			$('.js-hd-main').parent().removeClass('is-active');
		}
	}

	on(window, 'resize', checkIsPc);

	// 漢堡選單
	$(document).on('click', '.js-toggle-hamburger', function () {
		if ($('#hd').hasClass('is-open')) {
			$('#hd').removeClass('is-open');
			// stopHtmlScrolling(false);

			$('.hd_main_block').scrollTop(0);

			if ($('.js-hd-main')) {
				$('.js-hd-main').parent().removeClass('is-active');
			}
		} else {
			$('#hd').addClass('is-open');
			// stopHtmlScrolling(true);
		}
	});

	// goto top
	$(document).on('click', '.js-goto-top', function () {
		$('html, body').animate({ scrollTop: 0 });
	});
	/* ---------------------------------------- [END] header選單 */

	function getW() {
		return window.innerWidth || document.documentElement.clientWidth;
	}

	// rellax
	let rellaxHeadline = null;
	let rellaxEn = null;

	const bulidRellax = () => {
		if (document?.querySelectorAll('.js-rellax-headline').length) {
      rellaxHeadline = new Rellax('.js-rellax-headline');
    }

    if (document?.querySelectorAll('.js-rellax').length) {
      new Rellax('.js-rellax');
    }
	};

  const destroyRellax = () => {
		if (rellaxHeadline !== null) rellaxHeadline.destroy();
	};

	window.addEventListener('load', function () {
		if (!rellaxEn) {
			rellaxEn = new Rellax('.js-rellax-en', {
				center: true,
        horizontal: true,
        vertical: true
			});
		}

		if (getW() < 1024) return;
		bulidRellax();
	});

	window.addEventListener('resize', function () {
		if (getW() >= 1024) {
      bulidRellax();
		} else {
      destroyRellax();
		}
	});



	const $hd_white_class = 'hd:white';
	const $hd = document.querySelector('#hd');
	const $aboutSec = document.querySelector('[data-skeleton]');

	function isInViewport(el) {
		const rect = el.getBoundingClientRect();
		const isVisible = el.offsetHeight !== 0;
		return isVisible && rect.top >= 0 && rect.left <= (window.innerWidth || document.documentElement.clientWidth);
	}

	const checkIfOverKv = () => {
		if (isInViewport($aboutSec)) {
			$hd.classList.add($hd_white_class);
			return;
		}
		$hd.classList.remove($hd_white_class);
	};

	window.addEventListener('scroll', checkIfOverKv);
	window.addEventListener('load', checkIfOverKv);

	// ---------------------------------------------------------------

  const $modals = document.querySelectorAll(".modal");
  const $html = document.querySelector("html");

  $modals.forEach(($modal) => {
    $modal.addEventListener('shown.bs.modal', () => {
      $html.classList.add('is-overflow');

      console.log('jo');
    });

    $modal.addEventListener('hidden.bs.modal', () => {
      $html.classList.remove('is-overflow');
    })
  })

})(window, document);
/* ---------------------------------------- [END] Document Ready */
